import React, { useEffect } from "react"
import {
  Typography,
  Card,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Input,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CardActions,
  CardHeader,
  CardContent,
  Collapse,
  Button,
  Tooltip,
} from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
  Edit,
  Slash,
  CheckCircleIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
} from "@iconsGallery"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"
import AddIcon from "@material-ui/icons/Add"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles({
  basicIconProperty: {
    color: "rgba(158, 158, 158, 1)",
    fontSize: "30px",
  },
  iconUpDown: {
    fontSize: "30px",
    color: "rgba(0, 0, 0, 1)",
  },
  dividerBottom: {
    borderBottom: "0.1em solid rgba(0, 0, 0, 0.1)",
  },
  button: {
    backgroundColor: "var(--primaryColor)",
    color: "#fff",
    "&:disabled": {
      backgroundColor: "rgba(158, 158, 158, 1)",
    },
  },
})

const defaultRenderHeader = (props) => {
  const {
    theme,
    classes,
    edit,
    setEdit,
    collapseOpen,
    stopresetDataWhenClickOnAccordion,
    dontCallSetEditAfterClickingUpdatedata,
    setCollapseOpen,
    expanded,
    setExpanded,
    handleExpandClick,
    updateData,
    resetData = () => "",
    isEditMode = true,
    setAnyChanges = () => { },
    isAnyChanges,
    donstShowIcons,
    upArrowIconHandler,
    downArrowIconHandler,
    showUpArrow,
    showDownArrow,
    ind,
    showButtonsArr,
    isWriteAllowed,
    setAllowprompt = () => { },
    iconDisplay = true,
    t,
  } = props || {}
  //console.log(props.label, "props.label")
  return (
    <CardHeader
      style={{ borderBottom: "1px solid #EEEEEE", cursor: "pointer" }}
      className={expanded === false ? classes.dividerBottom : ""}
      title={t(props.label)}
      onClick={() => {
        setEdit(true)
        !stopresetDataWhenClickOnAccordion && resetData()
        handleExpandClick()
        setAllowprompt(false)
        resetData()
      }}
      action={
        <div>
          {iconDisplay ? (
            <div style={{ display: "flex" }}>
              <span>
                <div
                  spacing={2}
                  style={{
                    display: "flex",
                    marginRight: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {showButtonsArr &&
                    showButtonsArr.map(
                      (val) =>
                        val &&
                        val.show && (
                          <Button
                            disabled={val.disabled && val.disabled}
                            style={{ marginRight: 10, ...(val.style || {}) }}
                            color={val.color}
                            variant={val.variant}
                            onClick={(e) => {
                              e.stopPropagation()
                              val.buttonHandler()
                            }}
                            startIcon={val.icon}
                          >
                            {val.buttonText && t(val.buttonText)}
                          </Button>
                        )
                    )}
                </div>
              </span>
              {!donstShowIcons && props.edit ? (
                <>
                  {expanded === true && isEditMode === true && (
                    <Tooltip title={t("Edit")} placement="top">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation()
                          setEdit(false)
                        }}
                        style={{ marginRight: "13px" }}
                        disabled={isWriteAllowed ? false : true}
                      >
                        <Edit className={classes.basicIconProperty} />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              ) : (
                <>
                  {!donstShowIcons && expanded === true ? (
                    <>
                      <Tooltip title={t("Cancel")} placement="top">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation()
                            resetData()
                            setAnyChanges(false)
                            setEdit(true)
                            setAllowprompt(false)
                          }}
                          style={{ marginRight: "20px" }}
                        >
                          <Slash
                            style={{ color: "red" }}
                            className={classes.basicIconProperty}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("Save")} placement="top">
                        <IconButton
                          style={{
                            marginRight: "18px",
                            pointerEvents: `${isAnyChanges ? "unset" : "none"}`,
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            setAnyChanges(false)
                            updateData(props)
                            if (
                              dontCallSetEditAfterClickingUpdatedata &&
                              dontCallSetEditAfterClickingUpdatedata
                            ) {
                              setEdit(false)
                            } else {
                              setEdit(true)
                            }
                          }}
                        >
                          <CheckCircleIcon
                            style={{
                              color: `${isAnyChanges ? "#4CAF50" : "#CCC"}`,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      {showUpArrow && (
                        <IconButton
                          style={{ marginRight: "18px", fontSize: "small" }}
                          onClick={() => upArrowIconHandler(ind)}
                        >
                          <ArrowUpwardIcon />
                        </IconButton>
                      )}
                      {showDownArrow && (
                        <IconButton
                          style={{ marginRight: "18px" }}
                          onClick={() => downArrowIconHandler(ind)}
                        >
                          <ArrowDownwardIcon />
                        </IconButton>
                      )}
                    </>
                  )}
                </>
              )}
              {expanded === true ? (
                <IconButton>
                  <ExpandLessIcon className={classes.iconUpDown} />
                </IconButton>
              ) : (
                <IconButton>
                  <ExpandMoreIcon className={classes.iconUpDown} />
                </IconButton>
              )}
            </div>
          ) : (
            <>
              <div style={{ display: "flex" }}>
                <span>
                  <div
                    spacing={2}
                    style={{ display: "flex", marginRight: "10px" }}
                  >
                    {showButtonsArr &&
                      showButtonsArr.map(
                        (val) =>
                          val &&
                          val.show && (
                            <Button
                              style={{ marginRight: 10 }}
                              color={val.color}
                              variant={val.variant}
                              onClick={val.buttonHandler && val.buttonHandler}
                              startIcon={val.icon}
                            >
                              {val.buttonText && val.buttonText}
                            </Button>
                          )
                      )}
                  </div>
                </span>
                {!donstShowIcons && props.edit ? (
                  <>
                    {expanded === true && isEditMode === true && (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation()
                          setEdit(false)
                        }}
                        style={{ marginRight: "13px" }}
                        disabled={isWriteAllowed ? false : true}
                      >
                        <Edit className={classes.basicIconProperty} />
                      </IconButton>
                    )}
                  </>
                ) : (
                  <>
                    {showUpArrow && (
                      <IconButton
                        style={{ marginRight: "18px", fontSize: "small" }}
                        onClick={() => upArrowIconHandler(ind)}
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                    )}
                    {showDownArrow && (
                      <IconButton
                        style={{ marginRight: "18px" }}
                        onClick={() => downArrowIconHandler(ind)}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    )}
                  </>
                )}
                {expanded === true ? (
                  <IconButton>
                    <ExpandLessIcon className={classes.iconUpDown} />
                  </IconButton>
                ) : (
                  <IconButton>
                    <ExpandMoreIcon className={classes.iconUpDown} />
                  </IconButton>
                )}
              </div>
            </>
          )}
        </div>
      }
    ></CardHeader>
  )
}
const defaultRenderContainer = (props) => {
  return <></>
}
const defaultRenderFooter = (props) => {
  return <></>
}

function AccordianPanel(props) {
  const {
    isEditMode = true,
    isOpen = props.isOpen ? props.isOpen : false,
    renderHeader = defaultRenderHeader,
    renderContainer = defaultRenderContainer,
    renderFooter = defaultRenderFooter,
    isEditView = true,
    isWriteAllowed = false,
    iconDisplay = true,
    ...remainingProps
  } = props || {}
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const [edit, setEdit] = React.useState(isEditView)
  const [expanded, setExpanded] = React.useState(isOpen)
  const label = props.label
  const handleExpandClick = () => setExpanded(!expanded)
  const [hideAccordian, setHideAccordain] = React.useState(true)

  const stateProps = {
    theme,
    classes,
    edit,
    setEdit,
    expanded,
    setExpanded,
    handleExpandClick,
    label,
    isEditMode,
    isWriteAllowed,
    iconDisplay,
    t,
    ...remainingProps,
  }
  useEffect(() => {
    setExpanded(isOpen)
  }, [isOpen])

  useEffect(() => {
    setHideAccordain(false)
  }, [hideAccordian])

  return (
    <Card
      style={{
        boxShadow:
          "rgb(63 63 68 / 5%) 0px 0px 0px, rgb(63 63 68 / 15%) 0px 1px 2px",
      }}
      className={`base-accordion ${expanded === true ? "active" : "inactive"
        } cls-${label.replaceAll(" ", "").toLocaleLowerCase()}-kit`}
    >
      {renderHeader(stateProps)}
      {expanded === true && renderContainer(stateProps)}
      {expanded === true && renderFooter(stateProps)}
    </Card>
  )
}

export { AccordianPanel }
export default AccordianPanel
